<template>
  <van-tabs v-model="tabActive" title-active-color="#387ff5" title-inactive-color="#999" color="#387ff5"
            @change="tabChange">
    <van-tab name='0' title="积分兑换">
    </van-tab>
    <van-tab name='1' title="服务报名">
    </van-tab>
    <iframe id="iframe" frameborder="0" allowfullscreen="true" @load="sendMessage" scrolling="yes" ref="iframe"></iframe>
  </van-tabs>
</template>

<script>

export default {
  data() {
    return {
      tabActive: '0',
      url: '',
      options: [
        {
          appCode: 'app_integral',
          appName: '积分通',
          appId: 193,
          // appUrl: "http://192.168.0.70:8084?url=integral"
          appUrl: "http://192.168.0.40:96/form-h5?url=integral"
        }, {
          appCode: 'app_zyfw',
          appName: '志愿',
          appId: 219,
          // appUrl: "http://192.168.0.70:8084?url=voluntUser"
          appUrl: "http://192.168.0.40:96/form-h5?url=voluntUser"
        }
      ],
      list: {},
      iframeWindow: null
    }
  },
  created() {
    // this.getApp()
  },
  mounted() {
    this.tabActive = '0'
    this.iframeWindow = this.$refs.iframe.contentWindow
    this.$nextTick(() => {
      this.tabChange()
    })
  },
  methods: {
    getApp() {
      this.options = []
      this.$http({
        url: this.$http.adornUrl('/wxapp/application/getMyAppList'),
        method: 'get',
        params: this.$http.adornParams({
          orgId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let myList = data.list.list && data.list.list.length > 0 ? data.list.list.map(element => {
            return {
              name: element.appName,
              appUrl: element.pageUrlProgram,
              appCode: element.appCode,
              appId: element.id
            }
          }) : []
          // app_integral
          // app_zyfw
          myList.map(item => {
            if (item.appCode == 'app_integral' || item.appCode == 'app_zyfw') {
              this.options.push(item)
            }
          })
          this.tabChange()
        } else {
          this.$message.error(data.msg)
          this.errorMsg = data.msg
        }
      })
    },
    tabChange() {
      this.options.map(item => {
        if (this.tabActive == '0' && item.appCode == 'app_integral') {
          this.list = item
          this.url = item.appUrl
        } else if (this.tabActive == '1' && item.appCode == 'app_zyfw') {
          this.list = item
          this.url = item.appUrl
        }
      })
      this.iframeWindow.location.replace(this.url)
    },
    sendMessage() {
      if (this.$cookies.get('token')) {
        this.$http({
          url: this.$http.adornUrl('/wxapp/application/jmgg/appToken'),
          method: 'get',
          params: this.$http.adornParams({
            appId: this.list.appId,
            orgId: this.$orgId,
            appCode: this.list.appCode
          })
        }).then(({data}) => {
          // console.log(window.__wxjs_environment, window.__wxjs_environment);
          let windowType = window.__wxjs_environment
          if (data && data.code === 0) {
            // 把appToken传给iframe
            this.iframeWindow.postMessage({
              appToken: data.appToken,
              token: this.$cookies.get('token') || sessionStorage.getItem('token'),
              appid: this.$appid,
              appId: this.appId,
              orgId: this.$orgId,
              code: this.list.appCode,
              orgUserId: this.$userId,
              userName: this.$userName,
              mobile: this.$globalData.userInfo.mobile,
              windowType: windowType,
              subarea: this.$subarea,
              subareaName: this.$subareaName,
              depth: 5,
              userType: 1,
              communityId: this.$communityId,
              communityName: this.$communityName,
              unitId: this.$unitId,
              unitName: this.$unitName,
              houseId: this.$houseId,
              houseName: this.$houseName,
              info: data.info,
            }, '*')
          } else {
            this.$message.error(data.msg)
            this.errorMsg = data.msg
          }
        })
      } else {
        this.iframeWindow.postMessage({
          appid: this.$appid,
          appId: this.appId,
          orgId: this.$orgId,
          code: this.list.appCode,
          orgUserId: this.$userId,
          userName: this.$userName,
          subarea: this.$subarea,
          subareaName: this.$subareaName,
          depth: 5,
          userType: 1,
          communityId: this.$communityId,
          communityName: this.$communityName,
          unitId: this.$unitId,
          unitName: this.$unitName,
          houseId: this.$houseId,
          houseName: this.$houseName,
        }, '*')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
iframe {
  display: block; /* iframes are inline by default */
  border: none; /* Reset default border */
  height: 100vh !important; /* Viewport-relative units */
  width: 100vw !important;
}
</style>
